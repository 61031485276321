import React, { ChangeEvent, useState, useRef, useEffect, useContext } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as securityHelper from "../helper/SecurityHelper";
import {fn_loginHandlerCallback} from '../helper/CmmnHelper'
import { useCookies } from 'react-cookie';
import {setItemWithExpireTime, USER_SESSION} from "../helper/SecurityHelper";
import errorCodeDic from "../util/errorCodeDic";
import { UserContext } from '../context/User';
import {Col, Container, Form, Row} from "react-bootstrap";
import logo_title from '../../src/assets/images/logo/logo_login.svg';
import logo_gsil from '../../src/assets/images/logo/logo_gsil.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FindIdModal from "./modal/FindIdModal";

export default function Login() {
  const navigate = useNavigate();
  const userIdReference = useRef<any>(null);
  const passwordReference = useRef(null);
  const user = securityHelper.getLoginUser();
  const [cookies, setCookie, removeCookie] = useCookies(['rememberUserId']);
  const [userId, setUserId] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const {setUser} = useContext<any>(UserContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  /*let urlParam:any = Object.fromEntries(new URLSearchParams(window.location.search));*/

  const fn_loginHandler = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    var userId = userIdReference.current.value
    // @ts-ignore
    var password = passwordReference.current.value

    if (userId.trim() === '') {
      alert("아이디를 입력해 주십시오");
      // @ts-ignore
      userIdReference.current.focus()
      return false;
    }
    securityHelper.login(userId, password, async (user: any) => {
      if(user.isSuccess){
        //localStorage.setItem(USER_SESSION, JSON.stringify(user.userData));
        setItemWithExpireTime(USER_SESSION, user.userData);
        setUser(user.userData);
        fn_loginHandlerCallback(user, navigate)
      }else{
        alert(errorCodeDic[user.errorCode]);
      }
    });
    return false;
  };
  useEffect(() => {
    if(user){
      fn_loginHandlerCallback(user, navigate);
    }
    console.log('cookies.rememberUserId : ', cookies.rememberUserId );
    // 아이디 쿠키 저장
    if(cookies.rememberUserId !== undefined) {
      setUserId(cookies.rememberUserId);
      setIsRemember(true);
    }
  },[])
  const handleOnChange = (e: any) => {
    setIsRemember(e.target.checked);
    if(e.target.checked){
      setCookie('rememberUserId', userIdReference.current.value, {maxAge: 2000});
    } else {
      removeCookie('rememberUserId');
    }
  }

  const [imgUri, setImgUri] = useState('url(/bg_login_01.jpeg)');

  return (
    <div className='bg-white bmc-txt-color-primary'>
      <FindIdModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} />
      <Container className='d-flex justify-content-between align-items-center w-screen h-screen px-0 overflow-y-hidden'>
        <Row className="w-100 h-100 mx-0">
          <Col bsPrefix="col-4">
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="d-flex flex-column justify-content-center align-items-center h-75">
                  <img src={logo_title} alt="로고" style={{width: '280px'}} />
                  <Form>
                    <input
                        ref={userIdReference}
                        placeholder="아이디를 입력하세요."
                        defaultValue={userId}
                        className='w-full bmc-form input mt-5 mb-2'
                        type='text'
                    />
                    <input
                        ref={passwordReference}
                        placeholder="비밀번호를 입력하세요."
                        className='w-full bmc-form input'
                        type='password'
                    />
                    <div className='d-flex justify-content-between align-items-center mt-3 mb-4'>
                      <div className="d-flex align-items-center">
                        <input className='form-check-input bmc-check me-2 mb-1' onChange={handleOnChange} checked={isRemember} type='checkbox' />
                        <div className='bmc-fs-b3 bmc-text-font_secondary01'>아이디 저장</div>
                      </div>
                      <div>
                        <div className='hover:cursor-pointer bmc-txt-color-main bmc-txt-size-b5 text-center underline underline-offset-2 decoration-[0.5px]'
                             onClick={() => setIsModalOpen(true)}>아이디 찾기</div>
                      </div>
                    </div>
                    <LoginBtn clickHandler={fn_loginHandler} />
                  </Form>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img src={logo_gsil} alt="로고" style={{width: '74px', height: '39px', marginBottom: 5}} />
                  <span className="bmc-txt-size-b7 bmc-txt-color-gray-01">(COPYRIGHT ⓒ 2023 BY GSIL Co., Ltd.. All rights reserved.)</span>
                </div>
              </div>
          </Col>
          <Col bsPrefix="col-8" className="px-0">
            <Carousel autoPlay infiniteLoop showArrows={false} showThumbs={false} width={'100%'} dynamicHeight={true} className="bmc-login">
              <div style={{backgroundImage:"url(/bg/bg_login_04.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
              </div>
              <div style={{backgroundImage:"url(/bg/bg_login_01_.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
                <div className='legend'>부산 일광지구 4BL 민간참여 공공주택 건설사업</div>
              </div>
              <div style={{backgroundImage:"url(/bg/bg_login_01.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}></div>
              <div style={{backgroundImage:"url(/bg/bg_login_03_.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
                <div className='legend'>부산국제아트센터 건립사업</div>
              </div>
              <div style={{backgroundImage:"url(/bg/bg_login_02.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
              </div>
              <div style={{backgroundImage:"url(/bg/bg_login_04_.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
                <div className='legend'>부산 시청 앞 행복주택 민간참여 공공주택 건설사업</div>
              </div>
              <div style={{backgroundImage:"url(/bg/bg_login_03.jpg)", backgroundSize:"cover", width:"100%", height:"100%"}}>
              </div>
            </Carousel>

            {/*<div className='bmc-login'>
              <div
                  className='w-screen h-screen position-absolute slide'
                  style={{
                    zIndex:-1,
                    backgroundColor: '#191a1d',
                    backgroundImage: 'url(/bg_login_01.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'top right',
                    backgroundSize: 'cover'}} />
              <div
                  className='w-screen h-screen position-absolute slide'
                  style={{
                    zIndex:-1,
                    backgroundColor: '#191a1d',
                    backgroundImage: 'url(/bg_login_02.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'top right',
                    backgroundSize: 'cover'}} />
              <div
                  className='w-screen h-screen position-absolute slide'
                  style={{
                    zIndex:-1,
                    backgroundColor: '#191a1d',
                    backgroundImage: 'url(/bg_login_03.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'top right',
                    backgroundSize: 'cover'}} />
            </div>*/}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const LoginBtn = ({ clickHandler }: any) => {
  return (
    <button type={"submit"}
        className='bmc-btn normal p w-100 py-3 bmc-fs-b1 my-3'
        onClick={clickHandler}
    >
      로그인 하기
    </button>
  );
};

const NfcBtn = ({ clickHandler }: any) => {
  return (
      <button className='mt-[1.25rem] font-preLight text-13 leading-18 w-full py-[0.5rem] md:text-18 outline-none bg-gradient-to-r from-gdNfcFrom to-gdNfcTo rounded-[5px]'
              onClick={clickHandler}>NFC태깅</button>
  );
};





